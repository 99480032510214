<template>
  <div>
    <b-modal
      id="modal-details"
      centered
      size="lg"
      title="Detalhes"
      ok-title="Confirmar"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
    >
      <b-row>

        <!-- Field: Origem -->
        <b-col
          cols="12"
          md="6"
          class="mt-25 mb-1"
        >
          <!-- <span>Origem:</span> -->
          <span class="font-medium-2">Origem:</span>
          <span class="font-medium-4 font-weight-bolder"> Orçamento</span>
        </b-col>

        <!-- Field: Paciente -->
        <b-col
          cols="12"
          md="6"
          class="mt-25 mb-1"
        >
          <!-- <span>Data de Efetivação:</span> -->
          <span class="font-medium-2">Data de Efetivação:</span>
          <span class="font-medium-4 font-weight-bolder"> 05/11/2021</span>
        </b-col>

        <b-col
          cols="12"
          md="12"
        >
          <!-- <b-table
            ref="refUserListTable"
            class="position-relative tableMinHeght"
            :items="fetchUsers"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Nenhum resultado encontrado"
          /> -->
          <b-table
            striped
            hover
            :items="items"
            :fields="fields"
            responsive
            show-empty
            empty-text="Nenhum resultado encontrado"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BTable,
  },
  data() {
    return {
      fields: [
        {
          label: 'vencimento',
          key: 'dueDate',
        },
        {
          label: 'pago em',
          key: 'paymentDate',
        },
        {
          label: 'descrição',
          key: 'details',
        },
        {
          label: 'Valor',
          key: 'value',
        },
      ],
      items: [],
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
