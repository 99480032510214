<template>
  <b-form-input
    ref="inputRef"
    :value="formattedValue"
  />
</template>

<script>
import { watch } from '@vue/composition-api' // Vue 2: import { watch } from '@vue/composition-api'
import { useCurrencyInput } from 'vue-currency-input'
import { BFormInput } from 'bootstrap-vue'

export default {
  name: 'CurrencyInput',
  components: {
    BFormInput,
  },
  props: {
    value: {
      type: Number, // Vue 2: value
      default: () => 0,
    },
    options: {
      type: Object,
      default: () => ({
        currency: 'BRL',
        locale: 'pt-BR',
        autoDecimalDigits: true,
        currencyDisplay: 'hidden',
        valueRange: { min: -999999, max: 999999 }, // Maior que isso estoura o rabge do banco
      }),
    },
  },
  setup(props) {
    const {
      inputRef,
      formattedValue,
      setOptions,
      setValue,
    } = useCurrencyInput(props.options)

    watch(() => props.value, value => { // Vue 2: props.value
      setValue(value || 0)
    })

    watch(() => props.options, options => {
      setOptions(options)
    })

    return { inputRef, formattedValue }
  },
}
</script>
