import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const currency = value => {
  if (!value) return 'R$ 0,00'
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export const typeTransaction = value => {
  if (value === 'CREDIT') return 'Crédito'
  if (value === 'DEBIT') return 'Débito'
  return 'Estorno'
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const dateTime = (value, type = 'date', suffix = '') => {
  if (value && type === 'date') { // A data passada deve estar no padrão: YYYY-MM-DD
    const [YYYY, MM, DD] = (value.slice(0, 10)).split('-')
    return `${DD}/${MM}/${YYYY}`
  }
  if (value && type === 'dateTime') { // A data passada deve estar no padrão: YYYY-MM-DD HH:mm:ss
    const [YYYY, MM, DD] = (value.slice(0, 10)).split('-')
    const [H, m, s] = (value.slice(11, 19)).split(':')
    return `${DD}/${MM}/${YYYY} ${H}:${m}:${s}${suffix}`
  }
  if (value && type === 'dateTimeNoSecond') { // A data passada deve estar no padrão: YYYY-MM-DD HH:mm:ss
    const [YYYY, MM, DD] = (value.slice(0, 10)).split('-')
    // eslint-disable-next-line no-unused-vars
    const [H, m] = (value.slice(11, 19)).split(':')
    return `${DD}/${MM}/${YYYY} ${H}:${m}${suffix}`
  }
  if (value && type === 'time') { // A data passada deve estar no padrão: YYYY-MM-DD HH:mm:ss
    const [H, m, s] = (value.slice(11, 19)).split(':')
    return `${H}:${m}:${s}${suffix}`
  }
  if (value && type === 'timeNoSecond') { // A data passada deve estar no padrão: YYYY-MM-DD HH:mm:ss
    const [H, m] = (value.slice(11, 19)).split(':')
    return `${H}:${m}${suffix}`
  }
  return value
}

/**
 * Return date in ISO with Timezone
 * If format === dateTime returns in mySql dateTime format YYYY-MM-DD HH:MM:SS
 * @param {*} date
 * @param {*} format
 * @returns
 */
export const toISOStringWithTimezone = (date, format = null) => {
  const tzOffset = -date.getTimezoneOffset()
  const diff = tzOffset >= 0 ? '+' : '-'
  const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0')
  const result = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}${diff}${pad(tzOffset / 60)}:${pad(tzOffset % 60)}`
  if (format === 'dateTime') {
    return result.slice(0, 19).replace('T', ' ')
  }
  return result
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')
