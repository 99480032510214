import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    account: {},
    accounts: [],
  },
  getters: {},

  mutations: {
    SET(state, data) {
      state.account = data
    },
    SET_LIST(state, data) {
      state.accounts = data
    },
    ADD_IN_LIST(state, data) {
      if (Array.isArray(state.accounts)) state.accounts.push(data)
    },
    UPDATE_IN_LIST(state, data) {
      if (Array.isArray(state.accounts)) {
        const idx = state.accounts.findIndex(o => o.id === data.id)
        if (idx >= 0) Vue.set(state.accounts, idx, data)
      }
    },
    DELETE_REGISTER(state, payload) {
      const idx = state.accounts.data.findIndex(o => o.id === payload)
      if (idx >= 0) {
        state.accounts.data.splice(idx, 1)
        state.accounts.total -= 1
      }
      if (Array.isArray(state.accounts)) {
        const idx2 = state.accounts.findIndex(o => o.id === payload)
        if (idx2 >= 0) {
          state.accounts.splice(idx, 1)
        }
      }
      if (state.account && state.account.id === payload) {
        state.account = { }
      }
    },
  },

  actions: {
    async fetch({ state, commit }, args) {
      try {
        if (!state.accounts.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/accounts${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          if (!args) commit('SET_LIST', resp.data)
          else { commit('SET_LIST', resp.data) }
          return resp
        }
        return { data: state.accounts }
      } catch (e) {
        return e
      }
    },

    fetchOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/accounts/${id}`)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/account`, data)
          .then(response => {
            commit('SET', response.data)
            commit('ADD_IN_LIST', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/account/${data.id}`, data)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(e => {
            reject(e)
          })
      })
    },

    delete(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/account/${data.id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    reactive(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/account/reactive/${data.id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
