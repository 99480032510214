import { ref, watch, computed } from '@vue/composition-api'
import { currency, dateTime } from '@core/utils/filter'

import store from '@/store'

export default function useBillsList(root) {
  const refListTable = ref(null)
  const refListResponsibleTable = ref(null)

  const handleSituation = situation => {
    switch (situation) {
      case 'PAID':
        return 'Pago'
      case 'NOT_PAID':
        return 'Não Pago'
      case 'EXPIRED':
        return 'Vencido'
      default:
        return situation
    }
  }

  const tableColumns = ref([
    { key: '#' },
    {
      key: 'transactable',
      label: 'paciente',
      formatter: value => `${value.code} | ${value.name}`,
      tdClass: 'nameTdClass',
      sortable: true,
    },
    {
      key: 'expiration_date',
      label: 'vencimento',
      formatter: value => dateTime(value),
      tdClass: 'expirationDateTdClass',
      sortable: true,
    },
    {
      key: 'situation',
      label: 'Situação',
      formatter: value => handleSituation(value),
      tdClass: 'situationTdClass',
      sortable: true,
    },
    {
      key: 'delay',
      label: 'acréscimo ???',
      tdClass: 'delayTdClass',
      sortable: true,
    },
    {
      key: 'description',
      label: 'descrição',
      tdClass: 'descriptionTdClass',
      sortable: true,
    },
    {
      key: 'value_installment',
      label: 'valor',
      formatter: currency,
      tdClass: 'valueInstallmentTdClass',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ])

  const tableColumnsResponsible = ref([
    {
      key: 'responsible',
      label: 'responsável',
      tdClass: 'nameTdClass',
      sortable: true,
    },
  ])

  const tableColumnsDependent = ref([
    { key: '#' },
    {
      key: 'expiration_date',
      label: 'vencimento',
      formatter: value => dateTime(value),
      tdClass: 'expirationDateTdClass',
      sortable: true,
    },
    {
      key: 'situation',
      label: 'Situação',
      formatter: value => handleSituation(value),
      tdClass: 'situationTdClass',
      sortable: true,
    },
    {
      key: 'description',
      label: 'descrição',
      tdClass: 'descriptionTdClass',
      sortable: true,
    },
    {
      key: 'value_installment',
      label: 'valor',
      formatter: currency,
      tdClass: 'valueInstallmentTdClass',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ])

  const perPage = ref(10)
  const totalData = ref(0)
  const totalExpired = ref(0)
  const totalNotPaid = ref(0)
  const totalPaid = ref(0)
  const totalAgreement = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const receiveShowModal = ref(false)
  const agreementShowModal = ref(false)

  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  const formatDate = dte => `${dte.getFullYear()}-${(dte.getMonth() + 1) < 10 ? `0${dte.getMonth() + 1}` : (dte.getMonth() + 1)}-${dte.getDate() < 10 ? `0${dte.getDate()}` : dte.getDate()}`

  const filterStartDate = ref(formatDate(firstDay))
  const filterEndDate = ref(formatDate(lastDay))

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const showToast = (icon = 'success', title = 'Sucesso', text = 'Dados Salvos com Sucesso.', confirmButton = 'btn btn-success') => {
    root.$swal({
      icon,
      title,
      text,
      customClass: {
        confirmButton,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'Atenção', e.response.data.message, 'btn btn-warning')
    else if (e.response.status === 401) showToast('error', 'Atenção', 'Sua sessão expirou, faça novo login!', 'btn btn-danger')
    else showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
  }

  const saveReceivePayment = async data => {
    try {
      await store.dispatch('store-finance/payCreditInstallments', data)
      refetchData()
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const saveAgreement = async data => {
    try {
      await store.dispatch('store-finance/addAgreement', data)
      refetchData()
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const fetch = (ctx, callback) => {
    store
      .dispatch('store-finance/fetchBills', {
        q: searchQuery.value,
        type: 'CREDIT',
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        withTrashed: true,
      })
      .then(response => {
        const { data, total } = response.data.transactions

        callback(data)
        totalData.value = total
        totalExpired.value = response.data.totalExpired
        totalNotPaid.value = response.data.totalNotPaid
        totalPaid.value = response.data.totalPaid
        totalAgreement.value = response.data.totalAgreement
      })
      .catch(e => {
        catchErr(e)
      })
  }

  const fetchResponsibles = (ctx, callback) => {
    store
      .dispatch('store-patient/fetchResponsibles', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        withTrashed: true,
      })
      .then(response => {
        const { data, total } = response.data

        callback(data)
        totalData.value = total
      })
      .catch(e => {
        catchErr(e)
      })
  }

  // const fetchResponsibles = (ctx, callback) => {
  //   store
  //     .dispatch('store-finance/fetchBills', {
  //       q: '',
  //       type: 'CREDIT',
  //       perPage: perPage.value,
  //       page: currentPage.value,
  //       sortBy: sortBy.value,
  //       sort: isSortDirDesc.value ? 'desc' : 'asc',
  //       withTrashed: true,
  //     })
  //     .then(response => {
  //       const { data, total } = response.data.transactions

  //       callback(data)
  //       totalData.value = total
  //       totalExpired.value = response.data.totalExpired
  //       totalNotPaid.value = response.data.totalNotPaid
  //       totalPaid.value = response.data.totalPaid
  //       totalAgreement.value = response.data.totalAgreement
  //     })
  //     .catch(e => {
  //       catchErr(e)
  //     })
  // }

  return {
    tableColumns,
    tableColumnsResponsible,
    tableColumnsDependent,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    refListResponsibleTable,
    receiveShowModal,
    agreementShowModal,
    filterStartDate,
    filterEndDate,
    totalExpired,
    totalNotPaid,
    totalPaid,
    totalAgreement,

    fetch,
    fetchResponsibles,
    refetchData,
    saveReceivePayment,
    saveAgreement,
    handleSituation,
  }
}
