<template>
  <div>
    <b-modal
      v-if="patientInfo"
      id="modal-transaction-details"
      v-model="showModalTransaction"
      centered
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      title="Editar"
      ok-title="Confirmar"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      @ok="confirm"
      @cancel="cancel"
      @close="cancel"
    >
      <b-row>

        <!-- Field: Paciente -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Paciente"
            label-for="patient-name"
          >
            <b-input-group>
              <b-form-input
                id="patient-name"
                v-model="patientInfo.patientName"
                type="text"
                readonly
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Valor -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Valor"
            label-for="payment-value"
          >
            <b-input-group>
              <b-form-input
                id="payment-value"
                v-model="transactionValue"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Vencimento -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Vencimento"
            label-for="due-date"
          >
            <b-input-group>
              <b-form-input
                id="due-date"
                v-model="patientInfo.date"
                type="date"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Descrição -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Descrição"
            label-for="details"
          >
            <b-input-group>
              <b-form-input
                id="details"
                placeholder="Detalhes"
                type="text"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Divisória -->
        <b-col
          cols="12"
          md="12"
        >
          <hr>
        </b-col>

        <!-- Field: Acréscimo -->
        <b-col
          v-if="valueMode === 'add'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Acréscimo"
            label-for="add-value"
          >
            <b-input-group>
              <b-form-input
                id="add-value"
                placeholder="Valor do acréscimo"
                type="number"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="changeValueMode"
                >
                  <feather-icon icon="ArrowUpRightIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Desconto -->
        <b-col
          v-if="valueMode === 'remove'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Desconto"
            label-for="remove-value"
          >
            <b-input-group>
              <b-form-input
                id="remove-value"
                placeholder="Valor do desconto"
                type="number"
              />
              <b-input-group-append>
                <b-button
                  variant="danger"
                  @click="changeValueMode"
                >
                  <feather-icon icon="ArrowDownRightIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Multa (precent) -->
        <b-col
          v-if="fineMode === 'percent'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Multa (%)"
            label-for="percent-fees"
          >
            <b-input-group>
              <b-form-input
                id="percent-fees"
                placeholder="Valor percentual"
                type="text"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="changeFineMode"
                >
                  %
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Multa (modular) -->
        <b-col
          v-if="fineMode === 'modular'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Multa (R$)"
            label-for="modular-fees-value"
          >
            <b-input-group>
              <b-form-input
                id="modular-fees-value"
                placeholder="Valor fixo"
                type="text"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="changeFineMode"
                >
                  R$
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Juros (precent) -->
        <b-col
          v-if="feesMode === 'percent'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Juros (%)"
            label-for="percent-fees"
          >
            <b-input-group>
              <b-form-input
                id="percent-fees"
                placeholder="Valor percentual"
                type="text"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="changeFeesMode"
                >
                  %
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Juros (modular) -->
        <b-col
          v-if="feesMode === 'modular'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Juros (R$)"
            label-for="modular-fees-value"
          >
            <b-input-group>
              <b-form-input
                id="modular-fees-value"
                placeholder="Valor fixo"
                type="text"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="changeFeesMode"
                >
                  R$
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BFormInput, BInputGroup,
  BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  props: {
    showmodal: {
      type: Boolean,
      default: false,
    },
    patientData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModalTransaction: null,
      patientInfo: null,
      transactionValue: null,
      delayValue: null,
      feesMode: 'percent',
      fineMode: 'percent',
      valueMode: 'add',
    }
  },
  watch: {
    showmodal(showmodal) {
      if (showmodal === true) {
        this.showModalTransaction = showmodal
        this.patientInfo = this.patientData
        if (this.patientInfo.value) {
          this.transactionValue = this.patientInfo.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }
        if (this.patientInfo.delay) {
          this.delayValue = this.patientInfo.delay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }
      }
    },
  },
  methods: {
    confirm() {
      this.$emit('confirmed')
    },
    cancel() {
      this.$emit('canceled')
    },
    changeFeesMode() {
      if (this.feesMode === 'percent') {
        this.feesMode = 'modular'
      } else if (this.feesMode === 'modular') {
        this.feesMode = 'percent'
      }
    },
    changeFineMode() {
      if (this.fineMode === 'percent') {
        this.fineMode = 'modular'
      } else if (this.fineMode === 'modular') {
        this.fineMode = 'percent'
      }
    },
    changeValueMode() {
      if (this.valueMode === 'add') {
        this.valueMode = 'remove'
      } else if (this.valueMode === 'remove') {
        this.valueMode = 'add'
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
