<template>
  <div>
    <b-modal
      v-model="showLocal"
      centered
      size="lg"
      title="Detalhes do pagamento"
      hide-footer
      @hidden="hidden"
    >
      <b-row
        v-for="(bank, idx) in banks"
        :key="idx"
      >
        <!-- Field: Forma de Pagamento -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Forma de Pagamento"
            label-for="bank-payment-methods"
          >
            <v-select
              v-model="bank.paymentMethod"
              :searchable="false"
              :clearable="false"
              input-id="bank-payment-methods"
              label="label"
              :options="bankPaymentOptions"
              :reduce="option => option.value"
              placeholder="Selecione"
              @input="reset(idx)"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Valor -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Valor"
            label-for="bank-payment-value"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <span>R$</span>
              </b-input-group-prepend>
              <currency-input
                v-model="bank.paymentValue"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Plus / Minus icon -->
        <b-col
          cols="12"
          md="2"
        >
          <feather-icon
            v-if="idx === 0"
            class="mt-2 ml-2 cursor-pointer text-success"
            icon="PlusCircleIcon"
            size="28"
            @click="add"
          />

          <feather-icon
            v-else
            class="mt-2 ml-2 cursor-pointer text-danger"
            icon="MinusCircleIcon"
            size="28"
            @click="remove(idx)"
          />
        </b-col>

        <!-- Field: Nº do Cheque -->
        <b-col
          v-if="bank.paymentMethod === 'Cheque'"
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nº do Cheque"
            label-for="bank-check-number"
          >
            <b-input-group>
              <b-form-input
                id="bank-check-number"
                v-model="bank.checkNumber"
                placeholder="Nº do Cheque"
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Data de vencimento -->
        <b-col
          v-if="bank.paymentMethod === 'Cheque'"
          cols="12"
          md="6"
        >
          <b-form-group
            label="Vencimento"
            label-for="check-due-date"
          >
            <b-input-group>
              <b-form-input
                id="check-due-date"
                v-model="bank.checkDueDate"
                type="date"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>

      <!-- Footer -->
      <b-row>
        <b-col
          cols="12"
          md="6"
        />

        <b-col
          cols="12"
          md="3"
        >
          <b-button
            variant="outline-secondary"
            block
            @click="hidden"
          >
            Cancelar
          </b-button>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            block
            @click="save"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BInputGroup, BFormInput,
  BButton, BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, toRefs, watch,
} from '@vue/composition-api'
import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    CurrencyInput,

    vSelect,
  },

  props: {
    account: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { account } = toRefs(props)
    const { show } = toRefs(props)
    const showLocal = ref(false)

    const banks = ref([
      { paymentMethod: '', paymentValue: 0 },
    ])

    const bankPaymentOptions = ref([
      { label: 'Boleto', value: 'Boleto' },
      { label: 'PIX', value: 'PIX' },
      { label: 'Transferência', value: 'Transferência' },
      { label: 'Cheque', value: 'Cheque' },
    ])

    watch(show, newValue => {
      showLocal.value = newValue
      banks.value = [{ paymentMethod: '', paymentValue: 0 }]

      if (account.value.details) {
        banks.value = account.value.details
      }
    })

    const hidden = () => {
      emit('hidden')
    }

    const reset = idx => {
      banks.value.splice(idx, 1, {
        paymentMethod: banks.value[idx].paymentMethod,
        paymentValue: 0,
      })
    }

    const add = () => {
      banks.value.push({ paymentMethod: '', paymentValue: 0 })
    }

    const remove = idx => {
      banks.value.splice(idx, 1)
    }

    const save = () => {
      Object.assign(account.value, { details: banks.value })
      emit('save', account.value)
      hidden()
    }

    return {
      showLocal,
      banks,
      bankPaymentOptions,

      hidden,
      reset,
      add,
      remove,
      save,
    }
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
