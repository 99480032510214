import axios from '@axios'

export default {
  namespaced: true,
  state: {
    installment: {},
    installments: [],
  },
  getters: {},
  mutations: {},
  actions: {
    addInstallments(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/transactions`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addDebitInstallments(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/debit/transactions`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    changeInstallments(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/transactions`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    payDebitInstallments(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/pay/debit/transactions`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    payCreditInstallments(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/pay/credit/transactions`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addAgreement(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/agreements`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    editInstallment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/transactions/${data.transaction.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    readjustInstallments(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/transactions/batch/readjustment`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    async fetchBills(ctx, args) {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/transactions${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
        // if (!args) commit('SET_LIST', resp.data)
        // else { commit('SET_LIST', resp.data) }
        return resp
      } catch (e) {
        return e
      }
    },

    delete(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}/transaction/${data.transaction.id}`, { reason: data.reason }) // Put por causa de reason
          .then(response => {
            resolve(response)
          })
          .catch(e => { reject(e) })
      })
    },

    reactive(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/transaction/reactive/${data.id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
