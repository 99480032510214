<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <card-receive
          icon="ClockIcon"
          :statistic="totalNotPaid"
          statistic-title="A Receber"
          color="primary"
        />
      </b-col>

      <b-col
        cols="12"
        md="3"
      >
        <card-received
          icon="CheckIcon"
          :statistic="totalPaid"
          statistic-title="Recebidos"
          color="success"
        />
      </b-col>

      <b-col
        cols="12"
        md="3"
      >
        <card-expired
          icon="ArrowDownRightIcon"
          :statistic="totalExpired"
          statistic-title="Vencidos"
          color="danger"
        />
      </b-col>

      <b-col
        cols="12"
        md="3"
      >
        <card-agreement
          icon="DollarSignIcon"
          :statistic="totalAgreement"
          statistic-title="Acordos"
          color="success"
        />
      </b-col>

    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="2"
          >
            <!-- Total Sum -->
            <span>Total</span>
            <h2
              class="mt-25 font-weight-bolder"
            >
              <sup class="font-medium-1">R$</sup>
              <span class="font-large-1 font-weight-bolder">
                {{ sumValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '') }}
              </span>
            </h2>
          </b-col>

          <!-- Date Filter -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="De"
              label-for="filter-start-date"
            >
              <b-input-group>
                <b-form-input
                  id="filter-start-date"
                  v-model="filterStartDate"
                  type="date"
                  trim
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Até"
              label-for="filter-end-date"
            >
              <b-input-group>
                <b-form-input
                  id="filter-end-date"
                  v-model="filterEndDate"
                  type="date"
                  trim
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Button: Receber -->
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="primary"
              block
              class="mt-2"
              @click="showReceiveModal"
            >
              <span>Receber</span>
            </b-button>
          </b-col>

          <!-- Button: Acordo -->
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="danger"
              block
              class="mt-2"
              @click="showAgreementModal"
            >
              <span>Acordo</span>
            </b-button>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Filter: Type -->
          <b-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filterPatientResponsible"
              :reduce="option => option.value"
              :searchable="false"
              :clearable="false"
              input-id="filter-by-type"
              label="label"
              :options="filterPatientResponsibles"
              @input="bills = []; updValue()"
            />
          </b-col>

          <!-- Field: Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <!-- Table -->
      <b-table
        v-if="filterPatientResponsible === 'PATIENT'"
        ref="refListTable"
        class="position-relative tableMinHeght"
        :items="fetch"
        :tbody-tr-class="rowClass"
        small
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        select-mode="multi"
        no-sort-reset
      >

        <!-- Colunm: # -->
        <template #cell(#)="data">
          <b-form-checkbox
            v-if="data.item.situation !== 'PAID'"
            v-model="bills"
            :value="data.item"
            name="selected"
            unchecked-value="not_checked"
            @change="updValue"
          />
        </template>

        <!-- Column: Acréscimos -->
        <template #cell(delay)="data">
          {{ data.item.delay && data.item.delay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
        </template>

        <!-- Column: Value -->
        <template #cell(value)="data">
          {{ data.item.value && data.item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-patients-edit', params: { id: data.item.id } }"> <!-- alterar rota para o paciente correto, está redirecionando para a rota do fakedb (não existe) -->
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Ver Prontuário</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal.modal-details
            >
              <feather-icon icon="FileIcon" />
              <span class="align-middle ml-50">Detalhes</span> <!-- cancelar esta parcela específica (com observação do motivoo de cancelamento) -->
            </b-dropdown-item>

            <b-dropdown-item
              @click="handleEditBill(data)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <b-table
        v-else
        ref="refListResponsibleTable"
        class="position-relative tableMinHeght"
        :items="fetchResponsibles"
        :tbody-tr-class="rowClass"
        small
        responsive
        :fields="tableColumnsResponsible"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        select-mode="multi"
        no-sort-reset
      >
        <template #cell(responsible)="row">
          <span
            @click="row.toggleDetails"
          >
            {{ row.item.code }} | {{ row.item.name }}
          </span>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row
              v-for="(dependent, index) in row.item.dependents"
              :key="index"
              class="mb-2"
            >
              <b-col
                sm="2"
                class="text-sm-right"
              >
                <b>{{ dependent.code }} | {{ dependent.name }}:</b>
              </b-col>
              <b-col>
                <b-table
                  class="position-relative tableMinHeght"
                  :items="dependent.transactions"
                  :tbody-tr-class="rowClass"
                  small
                  responsive
                  :fields="tableColumnsDependent"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  show-empty
                  empty-text="Nenhum resultado encontrado"
                  :sort-desc.sync="isSortDirDesc"
                  select-mode="multi"
                  no-sort-reset
                >
                  <!-- Colunm: # -->
                  <template #cell(#)="data">
                    <b-form-checkbox
                      v-if="data.item.situation !== 'PAID'"
                      v-model="bills"
                      :value="data.item"
                      name="selected"
                      unchecked-value="not_checked"
                      @change="updValue"
                    />
                  </template>
                </b-table>

              </b-col>
            </b-row>
          </b-card>
        </template>

        <!-- Colunm: # -->
        <template #cell(#)="data">
          <b-form-checkbox
            v-if="data.item.situation !== 'PAID'"
            v-model="bills"
            :value="data.item"
            name="selected"
            unchecked-value="not_checked"
            @change="updValue"
          />
        </template>

        <!-- Column: Acréscimos -->
        <template #cell(delay)="data">
          {{ data.item.delay && data.item.delay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
        </template>

        <!-- Column: Value -->
        <template #cell(value)="data">
          {{ data.item.value && data.item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-patients-edit', params: { id: data.item.id } }"> <!-- alterar rota para o paciente correto, está redirecionando para a rota do fakedb (não existe) -->
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Ver Prontuário</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal.modal-details
            >
              <feather-icon icon="FileIcon" />
              <span class="align-middle ml-50">Detalhes</span> <!-- cancelar esta parcela específica (com observação do motivoo de cancelamento) -->
            </b-dropdown-item>

            <b-dropdown-item
              @click="handleEditBill(data)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!-- Modal: Transaction edit -->
    <modal-transaction-edit
      :showmodal="showmodal"
      :patient-data="patientData"
      @confirmed="confirmAndClose"
      @canceled="cancelAndClose"
    />

    <!-- Modal Receive -->
    <modal-receive
      :total-value="sumValue"
      :bills="bills"
      :show="receiveShowModal"
      :accounts="accountsToReceive"
      @save="saveReceivePayment"
      @hidden="receiveShowModal = false; bills = []; updValue()"
    />

    <!-- Modal Agreement -->
    <modal-agreement
      :sum-value="sumValue"
      :installments="bills"
      :show="agreementShowModal"
      @save="saveAgreement"
      @hidden="agreementShowModal = false; bills = []; updValue()"
    />

    <!-- Modal Details -->
    <modal-details />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormCheckbox,
  BDropdown, BDropdownItem, BPagination, BButton, BFormGroup, BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import financeStoreModule from '@/views/apps/finances/financeStoreModule'
// eslint-disable-next-line import/no-cycle
import patientStoreModule from '@/views/apps/patients/patientStoreModule'
import useStoreAccount from '@/views/apps/finances/general-cashier/tabs/position/positionStoreModule'
// eslint-disable-next-line import/no-cycle
import useBillsList from './useBillsList'
import modalAgreement from './modals/modalAgreement.vue'
import modalReceive from './modals/modalReceive.vue'
import modalTransactionEdit from './modals/modalTransactionEdit.vue'
import modalDetails from './modals/modalDetails.vue'
import cardReceive from './cardReceive.vue'
import cardReceived from './cardReceived.vue'
import cardExpired from './cardExpired.vue'
import cardAgreement from './cardAgreement.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormGroup,
    BInputGroup,

    modalAgreement,
    modalReceive,
    vSelect,
    modalTransactionEdit,
    modalDetails,
    cardReceive,
    cardReceived,
    cardExpired,
    cardAgreement,
  },
  setup(props, { root }) {
    const USER_APP_STORE_MODULE_FINANCE = 'store-finance'
    const USER_APP_STORE_MODULE_ACCOUNT = 'store-account'
    const USER_APP_STORE_MODULE_PATIENT = 'store-patient'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_PATIENT)) store.registerModule(USER_APP_STORE_MODULE_PATIENT, patientStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_FINANCE)) store.registerModule(USER_APP_STORE_MODULE_FINANCE, financeStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_ACCOUNT)) store.registerModule(USER_APP_STORE_MODULE_ACCOUNT, useStoreAccount)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_PATIENT)) store.unregisterModule(USER_APP_STORE_MODULE_PATIENT)
      if (store.hasModule(USER_APP_STORE_MODULE_FINANCE)) store.unregisterModule(USER_APP_STORE_MODULE_FINANCE)
      if (store.hasModule(USER_APP_STORE_MODULE_ACCOUNT)) store.unregisterModule(USER_APP_STORE_MODULE_ACCOUNT)
    })

    const accounts = ref([])
    const accountsToReceive = computed(() => accounts.value.filter(el => el.shows_in.some(e => e === 'TO_RECEIVE')))

    store.dispatch('store-account/fetch')
      .then(response => {
        accounts.value = response.data
      })
      .catch()

    const {
      tableColumns,
      tableColumnsResponsible,
      tableColumnsDependent,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refListResponsibleTable,
      receiveShowModal,
      agreementShowModal,
      filterStartDate,
      filterEndDate,
      totalExpired,
      totalNotPaid,
      totalPaid,
      totalAgreement,

      saveReceivePayment,
      saveAgreement,
      fetch,
      fetchResponsibles,
    } = useBillsList(root)

    const layoutSkin = computed(() => store.state.appConfig.layout.skin)

    const bills = ref([])
    const sumValue = ref(0)
    const filterPatientResponsible = ref('PATIENT')

    const filterPatientResponsibles = ref([
      { label: 'Paciente', value: 'PATIENT' },
      { label: 'Responsável', value: 'RESPONSIBLE' },
    ])

    const rowClass = (item, type) => {
      if (item && (type === 'row') && item.deleted_at) return 'strikeout'
      if (item && (type === 'row') && item.situation === 'EXPIRED') return layoutSkin.value === 'light' ? 'expired' : 'expired-dark'
      return null
    }

    const updValue = () => {
      sumValue.value = 0
      if (!bills.value.length) return
      sumValue.value = bills.value.map(el => el.value_installment).reduce((a, b) => a + b, 0)
    }

    const showReceiveModal = () => {
      if (!bills.value.length) {
        root.$swal({
          title: 'Aviso!',
          text: 'Selecione ao menos uma transação.',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }

      receiveShowModal.value = true
    }

    const showAgreementModal = () => {
      if (!bills.value.length) {
        root.$swal({
          title: 'Aviso!',
          text: 'Selecione ao menos uma transação.',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }

      // checar se todas as parcelas selecionadas são do mesmo paciente
      if (!bills.value.every(el => el.transactable_id === bills.value[0].transactable_id)) {
        root.$swal({
          title: 'Aviso!',
          text: 'Todas as transações devem pertencer ao mesmo paciente.',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }

      agreementShowModal.value = true
    }

    return {
      tableColumns,
      tableColumnsResponsible,
      tableColumnsDependent,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refListResponsibleTable,
      sumValue,
      bills,
      filterPatientResponsible,
      filterPatientResponsibles,
      receiveShowModal,
      agreementShowModal,
      accountsToReceive,
      filterStartDate,
      filterEndDate,
      totalExpired,
      totalNotPaid,
      totalPaid,
      totalAgreement,

      fetch,
      fetchResponsibles,
      rowClass,
      updValue,
      showReceiveModal,
      showAgreementModal,
      saveReceivePayment,
      saveAgreement,
    }
  },

  data() {
    return {
      showmodal: false,
      patientData: {
        value: 0,
      },
      agreementValue: 0,
    }
  },
  methods: {
    handleEditBill(data) {
      this.showmodal = true
      this.patientData = data.item
    },
    confirmAndClose() {
      this.showmodal = false
    },
    cancelAndClose() {
      this.showmodal = false
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
