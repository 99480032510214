import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

const addressBlank = {
  street: null,
  public_place: null, // string nullable
  number: null,
  complement: null,
  neighborhood: null,
  zip_code: null,
  observation: null,
  type_address: 'RES',
  city_id: null,
  state_id: null,
}

export default {
  namespaced: true,
  state: {
    patient: {
      phones: [],
    },
  },
  getters: {
    patient: state => {
      if (state.patient && !state.patient.address) {
        state.patient.address = addressBlank
      }

      if (state.patient && (!state.patient.responsibles || !state.patient.responsibles.length)) {
        state.patient.responsible = {
          address: addressBlank,
        }
      } else if (state.patient) {
        [state.patient.responsible] = state.patient.responsibles

        state.patient.responsibles[0].kinship = state.patient.responsibles[0].pivot.kinship

        if (!state.patient.responsible.address) state.patient.responsible.address = addressBlank
      }

      if (state.patient && state.patient.phones) {
        // eslint-disable-next-line array-callback-return
        state.patient.phones.map(p => {
          // eslint-disable-next-line no-param-reassign
          if (p.type === 'MOBILE') state.patient.phoneNumber = p.phone
          // eslint-disable-next-line no-param-reassign
          if (p.type === 'PHONE') state.patient.secondPhoneNumber = p.phone
          // eslint-disable-next-line no-param-reassign
          if (p.type === 'OTHER') state.patient.thirdPhoneNumber = p.phone
        })
      }
      return state.patient
    },
  },

  mutations: {
    SET_PATIENT(state, patient) {
      state.patient = patient
    },
    ADD_ANAMNESE(state, data) {
      state.patient.anamneses.push(data)
    },
    UPDATE_ANAMNESE(state, data) {
      const idx = state.patient.anamneses.findIndex(e => e.id === data.id)
      state.patient.anamneses.splice(idx, 1, data)
    },
    DELETE_ANAMNESE(state, data) {
      const idx = state.patient.anamneses.findIndex(e => e.id === data.id)
      state.patient.anamneses.splice(idx, 1)
    },
  },
  actions: {
    fetchPatients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.fetchPatients(queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPatient({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/patients/${id}`)
          .then(response => {
            commit('SET_PATIENT', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    add(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/patient`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update({ commit }, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/patient/${userData.id}`, userData)
          .then(response => {
            commit('SET_PATIENT', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    search(ctx, val) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/patient/search`, { search: val })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    changePhoto({ commit }, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/user/change-photo/${userData.id}`, { photo: userData.photo })
          .then(response => {
            commit('SET_PATIENT', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    changeReminder({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/user/change-reminder/${data.id}`, { reminder: data.reminder })
          .then(response => {
            commit('SET_PATIENT', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    updateHealthInsuranecDentalPlans({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/patient/update-health-insuranec-dental-plans/${data.patient.id}`, { healthInsuranceDentalPlans: data.healthInsuranceDentalPlans })
          .then(response => {
            commit('SET_PATIENT', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    delete(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/user/${userData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    async fetchResponsibles(ctx, args) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/responsibles${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    saveResponsible({ commit }, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/responsible/${userData.id}`, userData.responsible)
          .then(response => {
            commit('SET_PATIENT', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    detachResponsible({ commit }, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/responsible/detach/${userData.responsible.id}`, userData)
          .then(response => {
            commit('SET_PATIENT', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
